import Collection from "../collection-proto"
import Point from "./point"

const debug = false
// const debug = "PointsCollection"

export default class PointsCollection extends Collection<Point> {
    constructor(models: Point[]) {
        super(models)
    }

    static initFromJSON(data: any[]):PointsCollection {
        const snapshots: Point[] = _.map(data, (record:any)=>{
            debug && console.log(debug, 'initFromJSON', record)
            return new Point(record)
        })

        return new PointsCollection(snapshots)
    }

    getByCoordinates(lat: number, lng: number) {
        return _.find(this.models, (model:Point)=>model.lat === lat && model.lng === lng )
    }
}
