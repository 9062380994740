import React from 'react'
import PropTypes from 'prop-types'

import {Marker, Circle} from '@react-google-maps/api'
import {RADIUS_CIRCLE} from "../../constants/radius-circle"
import {POINT, QUEST} from "../../constants/model-type"
import _ from 'lodash'
import {lightenDarkenColor} from "../../utils/utils"

const INITIAL_Z_INDEX = 100000
const Z_INDEX_DECREASE = 50 // max number of markers that can share same space and be selectable

const debug = false
// const debug = "MapMarker"

export default class MapMarker extends React.PureComponent {

    static propTypes = {
        data: PropTypes.object
    }

    constructor(props) {
        super(props)

        this.state = {
            zIndex      : INITIAL_Z_INDEX, // for multiple markers sharing same space to be selectable
            showRadius  : false,
            showIcon    : this._showIcon()
        }
    }

    componentDidMount() {
        window.bus.subscribe("info-panel:show", this, this.onPanelShow)
        window.bus.subscribe("info-panel:hide", this, this.onPanelHide)
        window.bus.subscribe("preferences:filters-changed", this, this.onFiltersChanged)

    }

    componentWillUnmount() {
        window.bus.unsubscribe(this)
    }

    _showIcon = () => {
        if (window.localSettings.searchValue && !this.props.data.isSearched(window.localSettings.searchValue.toLowerCase())) return false

        switch (this.props.data.type) {
            case POINT:
                if (this.props.data.isCustom)   return window.localSettings.showCustomPoints
                else                            return !window.localSettings.hiddenPointTypes.includes(this.props.data.icon)

            case QUEST: return !window.localSettings.hiddenQuestTypes.includes(this.props.data.icon) &&
                               !window.localSettings.hiddenCategoryTypes.includes(this.props.data.category)
        }
    }

    onPanelShow = (data) => {
        if (data.id !== this.props.data.id) this.onPanelHide(data)
    }

    onPanelHide = (data) => {
        if (!window.localSettings.toggleMarkerRadius) {
            this.setState({
                showRadius: false
            })
        }
    }

    onFiltersChanged = () => {
        const showIcon = this._showIcon()
        if (showIcon !== this.state.showIcon) {
            this.setState({showIcon})
        }
    }

    onClick = (e) => {
        debug && console.log(debug, "onClick", e, this.props.data)

        this.setState({
            showRadius  : !this.state.showRadius,
            zIndex: this.state.zIndex - Z_INDEX_DECREASE
        }, ()=>{
            window.bus.triggerEvent("info-panel:show", this.props.data)
        })
    }

    render() {
        const circleOptions = _.assign({}, RADIUS_CIRCLE, {radius: this.props.data.radius})
        if (this.props.data.customColor) {
            circleOptions.strokeColor   = this.props.data.customColor
            circleOptions.fillColor     = lightenDarkenColor(this.props.data.customColor, 20)
        }

        debug && console.log(debug, "render", this.state.showIcon, this.state.showRadius, this.props.data)

        return (
            <>
                {this.state.showIcon &&
                    <Marker zIndex={this.state.zIndex}
                            position={this.props.data.position}
                            options={this.props.data.markerIcon}
                            onClick={this.onClick}/>}
                {this.state.showRadius &&
                    <Circle center={this.props.data.position}
                            options={circleOptions}/>}
            </>
        )
    }
}