import React from 'react'

import {Button, TextField} from "@mui/material"
import ColorSelector, {colorsGroups} from "./color/color"

// const debug = false
const debug = "AddForm"

export default class AddForm extends React.PureComponent {

    onNameInputChange = (e) => {
        debug && console.log(debug, "onNameInputChange", e.target.value, e)
        this.name = e.target.value
    }

    onCoordinatesInputChange = (e) => {
        debug && console.log(debug, "onCoordinatesInputChange", e.target.value, e)
        this.coordinates = e.target.value
    }

    onRadiusInputChange = (e) => {
        debug && console.log(debug, "onRadiusInputChange", e.target.value, e)
        this.radius = e.target.value
    }

    onColorChange = (color) => {
        debug && console.log(debug, "onColorChange", color)
        this.color = color
    }

    onAdd = () => {
        debug && console.log(debug, "onAdd", this.coordinates, this.radius)

        window.bus.triggerEvent("add-points", [{
            name        : this.name,
            coordinates : this.coordinates,
            radius      : parseInt(this.radius),
            customColor : this.color || colorsGroups[0][0],
            isCustom    : true
        }])
    }

    render() {
        debug && console.log(debug, "render")

        return (
            <>
                <div className="add-panel-row">
                    <TextField label        = "Название"
                               variant      = "outlined"
                               defaultValue = {this.name}
                               onChange     = {this.onNameInputChange}/>
                </div>
                <div className="add-panel-row">
                    <TextField label        = "Координаты"
                               variant      = "outlined"
                               defaultValue = {this.coordinates}
                               onChange     = {this.onCoordinatesInputChange}/>
                </div>
                <div className="add-panel-row">
                    <TextField label        = "Радиус"
                               variant      = "outlined"
                               defaultValue = {this.radius}
                               onChange     = {this.onRadiusInputChange}/>
                </div>

                <ColorSelector onChange={this.onColorChange}/>

                <div className="add-panel-row">
                    <Button variant="contained" onClick={this.onAdd}>Добавить</Button>
                </div>
            </>
        )
    }
}