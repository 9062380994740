import React from 'react'
import PropTypes from 'prop-types'

import {Checkbox} from "@mui/material"
import {toggleArrayValue} from "../../../utils/utils"
import CollapsedIcon from "../../../components/icons/collapsed-icon"
import ExpandedIcon from "../../../components/icons/expanded-icon"

import _ from 'lodash'
import Config from "../../../config/config"

const debug = false
// const debug = "FilterGroup"

export default class FilterGroup extends React.PureComponent {

    static propTypes = {
        filtersType     : PropTypes.string,
        options         : PropTypes.array,
        localSettingName: PropTypes.string,
        label           : PropTypes.string,
    }

    constructor(props) {
        super(props)

        this.updateCheckboxes()
    }

    onToggleFilter = (filter, id) => {
        debug && console.log(debug, "onToggleFilter", filter)

        switch (filter) {
            case 'all':
                if (this.allSelected)   window.localSettings[this.props.localSettingName] = _.map(this.props.options, o=>o.value)
                else                    window.localSettings[this.props.localSettingName] = []
                break

            default:
                window.localSettings[this.props.localSettingName] = toggleArrayValue(window.localSettings[this.props.localSettingName], id)
                break
        }

        window.localSettings.save()

        this.updateCheckboxes()
        this.forceUpdate(()=>{
            window.bus.triggerEvent("preferences:filters-changed")
        })
    }

    updateCheckboxes = () => {
        this.allSelected = _.isEmpty(_.intersection(window.localSettings[this.props.localSettingName], _.map(this.props.options, o=>o.value)))
    }

    toggleGroup = (e) => {
        e.stopPropagation()
        window.localSettings.expandedFilterGroups = toggleArrayValue(window.localSettings.expandedFilterGroups, this.props.filtersType)
        window.localSettings.save()
        this.forceUpdate()
    }

    render() {
        debug && console.log(debug, "render", window.localSettings, this.searchOptions)

        const config = Config.get()
        const isExpanded = window.localSettings.expandedFilterGroups.includes(this.props.filtersType)

        return (
            <React.Fragment>
                <div className="preference-row group-header" onClick={()=>{this.onToggleFilter('all')}}>
                    <div className="arrow-icon" onClick={this.toggleGroup}>
                        {!isExpanded && <CollapsedIcon/>}
                        {isExpanded && <ExpandedIcon/>}
                    </div>
                    <Checkbox checked={this.allSelected}/>
                    <div className="preference-label" onClick={this.toggleGroup}>{this.props.label}</div>
                </div>

                {isExpanded && _.map(this.props.options, (option,key)=>
                    <div key={key} className="preference-row" onClick={()=>{this.onToggleFilter('single', option.value)}}>
                        <Checkbox checked={!window.localSettings[this.props.localSettingName].includes(option.value)}/>
                        <div className="preference-label">
                            {option.icon &&
                                <img className="preference-icon" src={'https://{0}/icon/{1}'.f(config.domain, option.icon)} alt={option.icon}/>}

                            {option.label}
                        </div>
                    </div>
                )}
            </React.Fragment>
        )
    }
}