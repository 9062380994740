import LocalSettingsProto from "./local-settings-proto"

const debug = false
// const debug = "MapLocalSettings"

export default class MapLocalSettings extends LocalSettingsProto {

    toggleMarkerRadius      : boolean   = false
    hiddenPointTypes        : any[]     = []
    hiddenQuestTypes        : any[]     = []
    hiddenCategoryTypes     : any[]     = []
    searchValue             : string    = ''
    expandedFilterGroups    : any[]     = []

    showCustomPoints        : boolean   = false
    customPoints            : any[]     = []

    lastCsvLoadUrl          : string    = ''

    constructor() {
        super("paranoia-map")

        this.normalize(this._readFromLocalStorage())

        debug && console.log(debug, 'constructor', this._readFromLocalStorage(), this)
    }

    normalize(values: any) {
        this.toggleMarkerRadius     = _.get(values, 'toggleMarkerRadius', false)
        this.hiddenPointTypes       = _.get(values, 'hiddenPointTypes', [])
        this.hiddenQuestTypes       = _.get(values, 'hiddenQuestTypes', [])
        this.hiddenCategoryTypes    = _.get(values, 'hiddenCategoryTypes', [])
        this.searchValue            = _.get(values, 'searchValue', '')
        this.expandedFilterGroups   = _.get(values, 'expandedFilterGroups', [])

        this.showCustomPoints       = _.get(values, 'showCustomPoints', true)
        this.customPoints           = _.get(values, 'customPoints', [])

        this.lastCsvLoadUrl         = _.get(values, 'lastCsvLoadUrl', [])
    }

    toObject() {
        return {
            toggleMarkerRadius      : this.toggleMarkerRadius,
            hiddenPointTypes        : this.hiddenPointTypes,
            hiddenQuestTypes        : this.hiddenQuestTypes,
            hiddenCategoryTypes     : this.hiddenCategoryTypes,
            searchValue             : this.searchValue,
            expandedFilterGroups    : this.expandedFilterGroups,

            showCustomPoints        : this.showCustomPoints,
            customPoints            : this.customPoints,

            lastCsvLoadUrl          : this.lastCsvLoadUrl,
        }
    }
}