import React from 'react'
import PropTypes from 'prop-types'

import SlidingPanel from "react-sliding-side-panel"
import {Autocomplete, Switch, ToggleButton, TextField} from "@mui/material"
import _ from 'lodash'
import Scrollbars from "react-custom-scrollbars-2"
import Classes from "classnames"
import {FILTER_CATEGORIES, FILTER_POINTS, FILTER_QUESTS} from "../../constants/filter-constants"
import FilterGroup from "./filter-group/filter-group"
import CloseIcon from "../../components/icons/close-icon"
import MenuIcon from "../../components/icons/menu-icon"
import ClearPoints from "./clear-points/clear-points";

// const debug = false
const debug = "Preferences"

export default class Preferences extends React.PureComponent {

    static propTypes = {
        items: PropTypes.shape({
            points: PropTypes.object,
            quests: PropTypes.object
        })
    }

    state = {
        isOpen: false
    }

    constructor(props) {
        super(props);

        this.panelId = 'preferences'
        this.debouncedSearch = _.debounce(this._onSearch, 500)

        this.updateSize(true)

        debug && console.log(debug, "constructor", props.items)

        this.pointTypes = _(props.items.points.models)
            .map(m=>({
                value   : m.icon,
                icon    : m.icon,
            }))
            .uniqBy(m=>m.value)
            .orderBy(i=>i.value, ['asc'])
            .value()

        this.questTypes = _(props.items.quests.models)
            .map(m=>({
                value   : m.icon,
                icon    : m.icon,
            }))
            .uniqBy(m=>m.value)
            .orderBy(i=>i.value, ['asc'])
            .value()

        this.questCategories = _(props.items.quests.models)
            .map(m=>({
                value   : m.category,
                label   : m.category ? m.category : '- Без категории -',
            }))
            .uniqBy(m=>m.value)
            .orderBy(i=>i.label, ['asc'])
            .value()

        this.searchOptions = []
        /*_.map([...props.items.points.models, ...props.items.quests.models], m=>{
            _.map(m.searchParameters, (value, key)=>{this.searchOptions.push({label: value, id: key})})
        })
        this.searchOptions = _.uniqBy(this.searchOptions, o=>o.label)*/
    }

    componentDidMount() {
        window.bus.subscribe("panel:open", this, this.onPanelOpen)
        window.bus.subscribe("window-resize", this, this.updateSize)
    }

    componentWillUnmount() {
        this.debouncedSearch.cancel()
        window.bus.unsubscribe(this)
    }

    onPanelOpen = (id) => {
        if (id !== this.panelId && this.state.isOpen) {
            this.setState({
                isOpen: false
            })
        }
    }

    updateSize = (noUpdate = false) => {
        const panelSize = window.innerWidth > 700 ? 30 : 100

        if (this.panelSize !== panelSize) {
            this.panelSize = panelSize

            if (!noUpdate) this.forceUpdate()
        }
    }

    onToggleMenu = () => {
        this.setState({
            isOpen: !this.state.isOpen
        }, ()=> {
            if (this.state.isOpen) window.bus.triggerEvent("panel:open", this.panelId)
        })
    }

    onRadiusShowToggle = (e, value) => {
        debug && console.log(debug, "onRadiusShowToggle", value)
        window.localSettings.toggleMarkerRadius = value
        window.localSettings.save()
        this.forceUpdate()
    }

    onSearchChange = (e, value) => {
        debug && console.log(debug, "onSearchChange", e, value)
        this._onSearch(value)
    }

    onSearchInputChange = (e, value) => {
        debug && console.log(debug, "onSearchInputChange", e, value)
        this.debouncedSearch(value)
    }

    _onSearch = (value) => {
        this.debouncedSearch.cancel()

        window.localSettings.searchValue = value
        window.localSettings.save()

        window.bus.triggerEvent("preferences:filters-changed")
    }

    toggleCustomPoints = (e) => {
        window.localSettings.showCustomPoints = !window.localSettings.showCustomPoints
        window.localSettings.save()

        window.bus.triggerEvent("preferences:filters-changed")
        this.forceUpdate()
    }

    render() {
        debug && console.log(debug, "render", window.localSettings, this.searchOptions)

        const classes = Classes("preferences", {
            'mobile-device' : window.isMobileDevice
        })

        return (
            <React.Fragment>
                <div className="menu-button">
                    <ToggleButton value="check" selected={this.state.isOpen} onChange={this.onToggleMenu}>
                        {!this.state.isOpen && <MenuIcon />}
                        {!!this.state.isOpen && <CloseIcon />}
                    </ToggleButton>
                </div>
                <SlidingPanel panelClassName="preferences-panel-container"
                              type="right"
                              noBackdrop={true}
                              isOpen={this.state.isOpen}
                              size={this.panelSize}>
                    <div className={classes}>
                        <div className="preferences-title">Настройки</div>
                        <Scrollbars className               = "preferences-scroll"
                                    autoHide                = {true}
                                    autoHideTimeout         = {1000}
                                    autoHideDuration        = {200}>
                            <div className="preference-row">
                                <Switch className="preference-toggle" checked={window.localSettings.toggleMarkerRadius} onChange={this.onRadiusShowToggle}/>
                                <div className="preference-label">Сохранять отображение радиуса взятия когда маркер квеста/точки уже не выбран</div>
                            </div>

                            <div className="preference-row">
                                <Switch className="preference-toggle" checked={window.localSettings.showCustomPoints} onChange={this.toggleCustomPoints}/>
                                <div className="preference-label">Показывать добавленные точки</div>
                            </div>

                            <ClearPoints/>

                            <div className="preference-group">
                                <div className="group-title">Фильтры</div>

                                <Autocomplete className="search-input"
                                              freeSolo
                                              value={window.localSettings.searchValue}
                                              options={this.searchOptions}
                                              renderInput={(params) => <TextField {...params} label="Поиск по названию, описанию или номеру"/>}
                                              onInputChange={this.onSearchInputChange}
                                              onChange={this.onSearchChange}/>

                                <FilterGroup filtersType        = {FILTER_POINTS}
                                             label              = "Все точки"
                                             localSettingName   = "hiddenPointTypes"
                                             options            = {this.pointTypes}/>

                                <FilterGroup filtersType        = {FILTER_QUESTS}
                                             label              = "Все квесты"
                                             localSettingName   = "hiddenQuestTypes"
                                             options            = {this.questTypes}/>

                                <FilterGroup filtersType        = {FILTER_CATEGORIES}
                                             label              = "Все категории квестов"
                                             localSettingName   = "hiddenCategoryTypes"
                                             options            = {this.questCategories}/>
                            </div>
                        </Scrollbars>
                    </div>
                </SlidingPanel>
            </React.Fragment>
        )
    }
}