import CollectionProto from "../collection-proto"
// @ts-ignore
import Quest from "./quest"

const debug = false
// const debug = "QuestsCollection"

export default class QuestsCollection extends CollectionProto<Quest> {

    constructor(models: Quest[]) {
        super(models)
    }

    static initFromJSON(data: any[]):QuestsCollection {
        const snapshots: Quest[] = _.map(data, (record:any)=>{
            debug && console.log(debug, 'initFromJSON', record)
            return new Quest(record)
        })

        return new QuestsCollection(snapshots)
    }
}
