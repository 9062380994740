import ModelProto from "../model-proto"
import {ModelType, POINT} from "../../constants/model-type"

export default class Point extends ModelProto {

    type               : ModelType

    alliance           : string
    fraction           : string

    level              : string
    owner              : string
    profit_fraction    : string
    profit_user        : string

    region             : string

    isCustom           : boolean

    constructor(data:any) {
        super(data)

        this.type               = ModelType.POINT

        this.alliance           = data['alliance']
        this.fraction           = data['fraction']

        this.level              = data['level']
        this.owner              = data['owner']
        this.profit_fraction    = data['profit_fraction']
        this.profit_user        = data['profit_user']

        this.region             = data['region']

        this.isCustom           = data['isCustom']
    }

    get infoPrams() {
        let res: any[]
        if (this.isCustom) {
            res = []
            res.push({label: "Радиус",       value: '{0} метров'.f(this.radius)})
            res.push({label: "Координаты",      value: this.coordinates})
        } else {
            res = [
                {label: "Номер",                value: this.number},
                {                               value: '{0} · {1}'.f(this.alliance, this.fraction)},
                {},
            ]

            if (this.description) {
                res.push({label: "Описание",  value: this.description})
                res.push({})
            }

            res.push({label: "Регион",               value: this.region})
            res.push({label: "Доход (влад./фрак.)",  value: '{0}/{1}'.f(this.profit_user, this.profit_fraction)})
            res.push({label: "Радиус захвата",       value: '{0} метров'.f(this.radius)})

            const available = this.availabilityHours
            if (available) res.push({label: "Доступен", value: available})

            const skills = this.skillValues
            if (skills) res.push({label: "Требует для взятия", value: skills})

            res.push({label: "Уровень точки",   value: this.level})
            res.push({label: "Координаты",      value: this.coordinates})
        }


        return res
    }

    get searchParameters() {
        return _.compact([
            this.name,
            this.alliance,
            this.fraction,
            this.region
        ])
    }

    isSearched(searchedValue: string) {
        return _.some([
            this.number,
        ], (value:number) => value && value.toString().includes(searchedValue)) ||
            _.some([
            this.name,
            this.alliance,
            this.fraction,
            this.region,
            this.description
        ], (text:string) => text && text.toLowerCase().includes(searchedValue))
    }
}
