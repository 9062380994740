import React from 'react'
import PropTypes from 'prop-types'

export default class InfoParam extends React.PureComponent {

    static propTypes = {
        data: PropTypes.object,
    }

    render() {
        return (
            <div className="param">
                {!!this.props.data.label &&
                <div className="label">
                    {this.props.data.label}:
                </div>}
                {!!this.props.data.value &&
                <div className="value">
                    {this.props.data.value}
                </div>}
            </div>
        )
    }
}