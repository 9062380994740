interface ConfigObject {
    "domain"            : string
    "dataUrl"           : string
    "googleMapsApiKey"  : string

    "center"            : {
        "lat": number
        "lng": number
    },

    "zoom"              : number
}

export default class Config {
    private static _data: ConfigObject;

    static async load() {
        const res = await fetch('config.json')
        const json = await res.json()
        this._data = json as ConfigObject
    }

    static get() {
        return this._data
    }
}