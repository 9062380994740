import {ModelInterface} from "./collection-proto"
import Config from "../config/config";

const debug = false
// const debug = "ModelProto"

export default class ModelProto implements ModelInterface {

    id              : ID
    name            : string

    capture1from    : string
    capture1to      : string
    capture2from    : string
    capture2to      : string

    coordinates     : string
    description     : string
    icon            : string

    number          : number

    radius          : number
    skill           : string

    lat             : number
    lng             : number

    customColor     : string

    customIcon      : any

    constructor(data:any) {

        this.id                 = data['id']
        this.name               = data['name']

        this.capture1from       = data['capture1from']
        this.capture1to         = data['capture1to']
        this.capture2from       = data['capture2from']
        this.capture2to         = data['capture2to']

        this.coordinates        = data['coordinates']
        this.description        = data['description']
        this.icon               = data['icon']

        this.number             = data['number']

        this.radius             = data['radius']
        this.skill              = data['skill']

        debug && console.log(debug, 'constructor', data, this)

        const latlng = this.coordinates.split(',')
        this.lat = parseFloat(latlng[0])
        this.lng = parseFloat(latlng[1])

        this.customColor        = _.get(data, 'customColor', '#b30000')
        if (!this.icon) {
            // https://yqnn.github.io/svg-path-editor/
            // CustomPointIcon path -> scale 2
            // Download as SVG (fill: #b30000AA, stroke: #b30000, stroke-width: 2)
            // convert svg to png (https://svgtopng.com/)
            let url
            switch (this.customColor) {
                case '#b30000'  : url = require('../assets/custom-point-1.png'); break
                case '#a46a00'  : url = require('../assets/custom-point-2.png'); break
                case '#009f08'  : url = require('../assets/custom-point-3.png'); break
                case '#007493'  : url = require('../assets/custom-point-4.png'); break
                case '#0029a1'  : url = require('../assets/custom-point-5.png'); break

                default         : url = require('../assets/custom-point-default.png'); break
            }

            this.customIcon = {
                url     : url,
                anchor  : { x: 15, y: 38 }
            }
        }
    }

    get position() {return {lat: this.lat, lng: this.lng}}
    get markerIcon() {

        switch (this.name) {
            case 'Радиоактивный барсук':
                return {
                    icon    : {
                        // url     : require('../assets/badger.svg').default,
                        url     : require('../assets/radioactive-badger.png'),
                        anchor  : { x: 24, y: 24 }
                    },
                }

            default:
                const config = Config.get()
                return this.icon ?
                    {icon: 'https://{0}/icon/{1}'.f(config.domain, this.icon)}
                    :
                    {icon: this.customIcon /*new CustomPointIcon(this.customColor)*/}
        }
    }
    get availabilityHours() {
        return "{0}{1}{2}".f(
            !!this.capture1from && !!this.capture1to && this.capture1from !== this.capture1to ? "с {0} по {1}".f(this.capture1from,this.capture1to) : "",
            !!this.capture1from && !!this.capture2from && this.capture1from !== this.capture2from ? " " : "",
            !!this.capture2from && !!this.capture2to && this.capture2from !== this.capture2to ? "с {0} по {1}".f(this.capture2from,this.capture2to) : ""
        )
    }
    get skillValues() {
        let matches
        if (this.skill) {
            const regexp2 = new RegExp(/((ни один из)|(любой из)|(ни одна из)|(любая из))\((.+)\)/, 'gmis')
            matches = [...this.skill.matchAll(regexp2)]

            if (_.isEmpty(matches)) {
                // console.error("ModelProto skillValues error:", this.skill, matches)
                return this.skill !== '-' ? this.skill : null
            }

            return '{0} ({1})'.f(matches[0][1], matches[0][6])
        }

        return null
    }

    toJSON() {
        let res = {}
        _.mapKeys(this, (value:any,key:number)=>{
            // @ts-ignore
            res[key] = value
        })
        return res
    }
}
