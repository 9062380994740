import React from 'react'
import PropTypes from 'prop-types'

import SlidingPanel from "react-sliding-side-panel"
import InfoParam from "./info-param"
import _ from 'lodash'
import Classes from 'classnames'
import Scrollbars from "react-custom-scrollbars-2"
import {POINT, QUEST} from "../../constants/model-type"
import {IconButton} from "@mui/material";
import CloseIcon from "../../components/icons/close-icon"
import TrashIcon from "../../components/icons/trash-icon"

const debug = false
// const debug = "InfoPanel"

export default class InfoPanel extends React.PureComponent {

    static propTypes = {
        items: PropTypes.object,
    }

    state = {
        data: null
    }

    constructor(props) {
        super(props)

        this.updateSize(true)
    }

    componentDidMount() {
        window.bus.subscribe("info-panel:show", this, this.onShow)
        window.bus.subscribe("info-panel:hide", this, this.onHide)
        window.bus.subscribe("window-resize", this, this.updateSize)

        window.bus.subscribe("reload-completed", this, this.onReloadCompleted)
    }

    componentWillUnmount() {
        window.bus.unsubscribe(this)
    }

    updateSize = (noUpdate = false) => {
        const panelSize = window.innerHeight > 700 ? 40 : 60

        if (this.panelSize !== panelSize) {
            this.panelSize = panelSize

            if (!noUpdate) this.forceUpdate()
        }
    }

    onShow = (data) => {
        debug && console.log(debug, "onShow", data)
        this.setState({
            data: data
        })
    }

    onHide = () => {
        debug && console.log(debug, "onHide")
        this.setState({
            data: null
        })
    }

    onReloadCompleted = () => {
        if (this.state.data) {
            let newData
            switch (this.state.data.type) {
                case POINT: newData = this.props.items.points.get(this.state.data.id); break
                case QUEST: newData = this.props.items.quests.get(this.state.data.id); break
            }
            this.onShow(newData)
        }
    }

    onDelete = () => {
        window.bus.triggerEvent("delete-point", this.state.data.id)
        this.onHide()
    }

    render() {
        const classes = Classes("info-panel", {
            'default'       : window.innerWidth > 700,
            'small-screen'  : window.innerWidth <= 700,
            'mobile-device' : window.isMobileDevice
        })

        const showIcon = !_.get(this.state, 'data.isCustom', false) && _.get(this.state, 'data.markerIcon', false)

        return (
            <SlidingPanel panelClassName="info-panel-container"
                          type="bottom"
                          noBackdrop={true}
                          isOpen={!!this.state.data}
                          size={this.panelSize}>
                {!!this.state.data &&
                    <React.Fragment>
                        <div className={classes}>
                            <div className="info-buttons-panel">
                                <IconButton size="large" color="default" onClick={this.onHide}>
                                    <CloseIcon />
                                </IconButton>
                            </div>

                            <div className="info-title">
                                {showIcon &&
                                    <img className  = "icon"
                                         src        = {this.state.data.markerIcon.icon.url || this.state.data.markerIcon.icon}
                                         alt        = {this.state.data.icon}/>}
                                <div className="name">{this.state.data.name}</div>
                            </div>
                            <Scrollbars className="info-scroll">
                                {_.map(this.state.data.infoPrams, (param, key) => <InfoParam key={key} data={param}/>)}

                                {this.state.data.isCustom &&
                                    <div className="delete-button" onClick={this.onDelete}>
                                        <TrashIcon className="delete-icon"/>
                                        Удалить точку
                                    </div>}
                            </Scrollbars>
                        </div>
                    </React.Fragment>}
            </SlidingPanel>
        )
    }
}