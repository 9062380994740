import React, {useState} from 'react'

import LoadingButton from '@mui/lab/LoadingButton';
import {Alert, TextField, Link, IconButton} from "@mui/material"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import csvToJson from "./csv-to-json"
import ColorSelector, {colorsGroups} from "./color/color"

// const debug = false
const debug = "AddFromGoogleSheet"

export default function AddFromGoogleSheet(){

    const [googleSheetURL, setGoogleSheetURL]   = useState<string>(window.localSettings.lastCsvLoadUrl)
    const [color, setColor]                     = useState<string>(colorsGroups[0][0])
    const [loading, setLoading]                 = useState<boolean>(false)
    const [errorMessage, setErrorMessage]       = useState<string | boolean>(false)
    const [showHelp, setShowHelp]               = useState<boolean>(false)

    const toggleHelp = (e:any) => {
        debug && console.log(debug, "toggleHelp", e)
        setShowHelp(!showHelp)
    }

    const onUrlInputChange = (e:any) => {
        debug && console.log(debug, "onNameInputChange", e.target.value, e)

        const csvLoadUrl = e.target.value

        window.localSettings.lastCsvLoadUrl = csvLoadUrl
        window.localSettings.save()

        setGoogleSheetURL(csvLoadUrl)
    }

    const onColorChange = (color: string) => {
        debug && console.log(debug, "onColorChange", color)
        setColor(color)
    }

    const onAdd = async () => {
        debug && console.log(debug, "onAdd", _.isEmpty(googleSheetURL), googleSheetURL)

        if (_.isEmpty(googleSheetURL)) {
            setErrorMessage('URL для загрузки CSV не указан')
            return
        }

        // https://docs.google.com/spreadsheets/d/e/2PACX-1vRXGKRzyAVvUffxoJCctmOoiirYQe1pMfWvfOsLeVMgAQ6jDz3PI2jYGo5YPhPgFdUehhYcq1yKQCc4/pub?output=csv

        setErrorMessage(false)
        setLoading(true)

        try {
            const response = await fetch(googleSheetURL)

            debug && console.log(debug, "onAdd response", response)

            if (response.ok) {
                const csv = await response.text()
                const pointsData = _(csvToJson(csv))
                    .filter((pointData:any)=>!!pointData.coordinates) // coordinates are required
                    .map((pointData:any)=>({
                        name        : pointData.name,
                        coordinates : pointData.coordinates,
                        radius      : parseInt(pointData.radius),
                        customColor : colorsGroups[0][pointData['color number']] || color,
                        isCustom    : true
                    }))
                    .value()

                debug && console.log(debug, "onAdd csv", csv, pointsData)

                window.bus.triggerEvent("add-points", pointsData)
            } else {
                throw Error('Load CSV error: {0}'.f(response.status))
            }
        } catch(e:any) {
            console.error("onAdd error:", e)
            setErrorMessage('Ошибка при попытке чтения CSV по указанному URL')
        }


        setLoading(false)
    }

    debug && console.log(debug, "render")

    return (
        <>
            <div className="add-panel-row">
                <TextField label        = "CSV URL"
                           variant      = "outlined"
                           defaultValue = {googleSheetURL}
                           onChange     = {onUrlInputChange}/>

                <IconButton style={{marginLeft: 10}} aria-label="Show help" onClick={toggleHelp}>
                    <InfoOutlinedIcon />
                </IconButton>
            </div>

            <ColorSelector onChange={onColorChange}/>

            <div className="add-panel-row">
                <LoadingButton variant="contained"
                               loading={loading}
                               onClick={onAdd}>
                    Добавить
                </LoadingButton>
            </div>

            {errorMessage &&
                <Alert style={{marginTop:10}} severity="error">{errorMessage}</Alert>}

            {showHelp &&
                <Alert style={{marginTop: 10, marginBottom: 20}} severity="info">
                    <p>
                        Поле CSV URL должно содержать ссылку на csv документ. Как её сделать для google sheet описано в
                        <Link style     = {{marginLeft: 5}}
                              target    = "_blank"
                              rel       = "noopener"
                              href      = "https://support.google.com/docs/answer/183965?hl=ru">документации</Link>.
                    </p>

                    <p>Выбрав вариант публикации "Таблица", следует указать формат ".csv".</p>

                    <p>Таблица должна содержать колонки: coordinates (обязательно), radius (опционально), name (опционально), color number (1-5, опционально).
                    Для всех записей, для которых цвет (color number) не задан в csv, он будет задан согласно выбранному цвету выше.</p>

                    <p>При добавлении все новые точки, совпадающе по координатам с уже существующими, заменят их (получат новый радиус, название и цвет, если отличаются).</p>
                </Alert>}
        </>
    )
}