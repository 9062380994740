import React, {useState} from 'react'

import {ToggleButton, ToggleButtonGroup} from "@mui/material"
import AdjustIcon from '@mui/icons-material/Adjust'

export const colorsGroups = [['#b30000', '#a46a00', '#009f08', '#007493', '#0029a1']]

type Props = {
    onChange: func
}

const debug = false
// const debug = "ColorSelector"

export default function ColorSelector(props:Props) {

    const [selectedColor, setSelectedColor] = useState('#b30000')

    const onColorChange = (e:any) => {
        debug && console.log(debug, "onColorChange", e.target.value, e)
        const newColor = e.target.value
        setSelectedColor(newColor)
        props.onChange(newColor)
    }

    debug && console.log(debug, "render")

    return (
        <>
            {_.map(colorsGroups, (colors:string[], key:number)=>
                <div key={key} className="add-panel-row">
                    <ToggleButtonGroup value    ={selectedColor}
                                       onChange ={onColorChange}>
                        {_.map(colors, (color:string)=> {
                            const isSelected = color === selectedColor
                            const style = {
                                width           : 42,
                                height          : 42,
                                backgroundColor : color,
                                opacity         : isSelected ? 1 : 0.8
                            }
                            return (
                                <ToggleButton key={color} value={color} style={style}>
                                    {isSelected && <AdjustIcon style={{color: '#FFFFFF'}}/>}
                                </ToggleButton>
                            )
                        })}
                    </ToggleButtonGroup>
                </div>
            )}
        </>
    )
}