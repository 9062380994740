// @ts-ignore
import AmpersandEvents from 'ampersand-events'

const debug = false
// const debug = "EventsBus"

export default class EventsBus {

    static _instance            : EventsBus

    private emitter             : any
    private debouncedEvents     : any

    constructor() {
        this.emitter = AmpersandEvents.createEmitter();
        this.debouncedEvents = {};

        if (EventsBus._instance) {
            return EventsBus._instance
        }

        EventsBus._instance = this
    }

    triggerEvent(eventName:string,...params:any) {
        debug && console.log(debug, 'triggerEvent', eventName, params)
        this.emitter.trigger(eventName,...params)
    }

    triggerEventDebounced(eventName:string,params:any) {
        if (!this.debouncedEvents[eventName]) this.debouncedEvents[eventName] = _.debounce(this.triggerEvent.bind(this), 10);
        this.debouncedEvents[eventName](eventName,params)
    }

    subscribeNoContext(eventName:string, callback:func) {
        this.emitter.on(eventName, callback, null)

        return () => {
            this.emitter.off(eventName, callback)
        }
    }

    subscribe(eventName:string,context:any,callback:func) {
        debug && console.log(debug, 'subscribe', eventName, context, callback)
        this.emitter.on(eventName, callback, context)
    }

    unsubscribe(context:any) {
        debug && console.log(debug, 'unsubscribe', context)

        if (!context) {
            console.error("EventsBus: unsubscribe without context will unsubscribe All events!");
            return
        }

        this.emitter.off(null,null,context);
    }

    unsubscribeFromEvent(eventName:string, context:any) {
        if (!context) {
            console.error("EventsBus: unsubscribe without context will unsubscribe All events!");
            return
        }

        this.emitter.off(eventName,null,context);
    }
}