import './App.scss'

import {} from './polyfill/string-format'

import Map from "./map/map"
import EventsBus from "./bus/events-bus"
import MapLocalSettings from "./local-settings/local-settings"

export default function App() {

    window.bus              = new EventsBus()
    window.localSettings    = new MapLocalSettings()

    window.addEventListener('resize', (event) => {
        window.bus.triggerEvent("window-resize")
    })

    window.isMobileDevice   = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

    return (
        <div style={{ height: "100%", width: '100%' }}>
            <Map />
        </div>
    )
}