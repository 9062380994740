import React from 'react'

import {createSvgIcon} from "@mui/material"

export default createSvgIcon(
    <svg viewBox="0 0 100 80" width="24" height="24">
        <rect fill="currentColor" width="100" height="20"></rect>
        <rect fill="currentColor" y="30" width="100" height="20"></rect>
        <rect fill="currentColor" y="60" width="100" height="20"></rect>
    </svg>, "menu"
)