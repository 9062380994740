import React from 'react'

import {IconButton} from "@mui/material"
import {TailSpin} from "react-loader-spinner"
import RefreshIcon from "../../components/icons/refresh-icon"

const debug = false
// const debug = "Refresh"

export default class Refresh extends React.PureComponent {

    state = {
        refreshing: false
    }

    componentDidMount() {
        window.bus.subscribe("reload-completed", this, this.onReloadCompleted)
    }

    componentWillUnmount() {
        window.bus.unsubscribe(this)
    }

    onRefresh = () => {
        if (this.state.refreshing) return

        this.setState({
            refreshing: true
        }, ()=>{
            window.bus.triggerEvent("reload-data")
        })
    }

    onReloadCompleted = () => {
        this.setState({
            refreshing: false
        })
    }

    render() {
        return (
            <div className="refresh-button">
                <IconButton size="large" color="default" disabled={this.state.refreshing} onClick={this.onRefresh}>
                    {!this.state.refreshing && <RefreshIcon />}

                    {this.state.refreshing &&
                        <TailSpin wrapperClass="refresh-spinner"
                                  height="100%"
                                  width="100%"
                                  color="gray"
                                  radius="1"
                                  visible={true}/>}
                </IconButton>
            </div>
        )
    }
}