import React, {useCallback, useState} from 'react'

import {Button} from "@mui/material"

const debug = false
// const debug = "ClearPoints"

export default function ClearPoints() {

    const [confirm, setConfirm] = useState(false)

    const attemptDelete = useCallback(() => {
        setConfirm(true)
    }, [])

    const onClearCustomPoints = useCallback(() => {
        window.bus.triggerEvent("clear-points")
        setConfirm(false)
    }, [])

    const onCancel = useCallback(() => {
        setConfirm(false)
    }, [])

    return (
        <div className="preference-row" style={{marginTop: 10}}>
            {!confirm && <Button variant="outlined" onClick={attemptDelete}>Удалить добавленные точки</Button>}

            {confirm &&
                <>
                    <Button style={{marginRight: 30}} variant="outlined" color="error" onClick={onClearCustomPoints}>Да, удалить</Button>
                    <Button variant="outlined" onClick={onCancel}>Отмена</Button>
                </>}
        </div>
    )
}