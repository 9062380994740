import _ from 'lodash'

export const toggleArrayValue = (arr, value, options) => {
    let newArr = arr.slice()
    if (newArr.includes(value)) _.remove(newArr, v=>v === value)
    else newArr.push(value)

    if (options && options.mandatorySelection && newArr.length === 0) newArr = arr.slice();

    return newArr
}

export const getCoordinates = (str) => {
    const regexp = /(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)/gi
    const match = str.match(regexp)
    if (_.isEmpty(match)) return false

    const coordinates = match[0].split(', ')
    return {
        lat: parseFloat(coordinates[0]),
        lng: parseFloat(coordinates[1])
    }
}

export const generateUIDWithCollisionChecking = (arrayIds = []) => {

    // array to object
    const _generatedUIDs = arrayIds.reduce((a, key) => Object.assign(a, { [key]: 0 }), {});

    while (true) {
        let uid = ("0000" + ((Math.random() * Math.pow(36, 4)) | 0).toString(36)).slice(-4);
        if (!_generatedUIDs.hasOwnProperty(uid)) {
            _generatedUIDs[uid] = true;
            return uid;
        }
    }
}

/**
 * Lighten or Darken Color
 *
 * The CSS preprocessors Sass and LESS can take any color and darken() or
 * lighten() it by a specific value. But no such ability is built into
 * JavaScript. This function takes colors in hex format (i.e. #F06D06, with or
 * without hash) and lightens or darkens them with a value.
 *
 * @param {String} colorCode The hex color code (with or without # prefix).
 * @param {Int} amount
 */
export const lightenDarkenColor = (colorCode, amount) => {
    var usePound = false;

    if (colorCode[0] == "#") {
        colorCode = colorCode.slice(1);
        usePound = true;
    }

    var num = parseInt(colorCode, 16);

    var r = (num >> 16) + amount;

    if (r > 255) {
        r = 255;
    } else if (r < 0) {
        r = 0;
    }

    var b = ((num >> 8) & 0x00FF) + amount;

    if (b > 255) {
        b = 255;
    } else if (b < 0) {
        b = 0;
    }

    var g = (num & 0x0000FF) + amount;

    if (g > 255) {
        g = 255;
    } else if (g < 0) {
        g = 0;
    }

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}

