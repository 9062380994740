import React from 'react'

import {GoogleMap, LoadScript} from '@react-google-maps/api'
import _ from 'lodash'

import MapMarker from "./marker/map-marker"
import QuestsCollection from "../models/quests/quests-collection"
import PointsCollection from "../models/points/points-collection"
import LoadingScreen from "./loading"
import {COLOR} from "../constants/color"
import Preferences from "./preferences/prefernces"
import InfoPanel from "./info/info-panel"
import Refresh from "./refresh/refresh"
import {getCoordinates} from "../utils/utils"
import Add from "./add/add"
import Point from "../models/points/point"
import Config from "../config/config"

const containerStyle = {
    width: '100%',
    height: '100%'
}

const debug = false
// const debug = "Map"

export default class Map extends React.PureComponent{

    constructor(props) {
        super(props)

        this.isCenteredToUserLocation   = false

        this.state                      = {
            items       : {},
            isLoaded    : false
        }
    }

    async componentDidMount() {
        window.bus.subscribe("reload-data", this, this.reloadData)
        window.bus.subscribe("add-points", this, this.addPoints)
        window.bus.subscribe("delete-point", this, this.deletePoint)
        window.bus.subscribe("clear-points", this, this.clearPoints)

        await Config.load()
        this.parseParams()
        this.loadData()
    }

    componentWillUnmount() {
        window.bus.unsubscribe(this)
    }

    parseParams = () => {
        const params = decodeURI(window.location.pathname).split('/')
        debug && console.log(debug, "parseParams", params)

        _.map(params, p=>{
            const coordinates = getCoordinates(p)
            if (coordinates) Config.get().center = coordinates
        })
    }

    reloadData = () => {
        debug && console.log(debug, "reloadData")

        this.loadData(()=>{
            debug && console.log(debug, "reloadData completed")
            window.bus.triggerEvent("reload-completed")
        })
    }

    loadData = (callback) => {
        const config = Config.get()
        debug && console.log(debug, "loadData", config)

        this.customPoints = PointsCollection.initFromJSON(window.localSettings.customPoints)

        fetch(config.dataUrl.f(config.domain))
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    items   : {
                        quests  : QuestsCollection.initFromJSON(json.quests),
                        points  : PointsCollection.initFromJSON(json.points),
                    },
                    isLoaded: true
                }, callback)
            })
    }

    addPoints = (arrayData) => {
        debug && console.log(debug, "addPoints", arrayData, this.customPoints)

        _.map(arrayData, pointData=>{
            const newPoint = new Point(pointData)
            const point = this.customPoints.getByCoordinates(newPoint.lat, newPoint.lng)

            if (point) {
                this.customPoints.replace(point.id, newPoint)
            } else {
                this.customPoints.add(new Point(pointData))
            }
        })

        window.localSettings.customPoints = this.customPoints.toJSON()
        window.localSettings.save()
        this.forceUpdate()
    }

    deletePoint = (id) => {
        debug && console.log(debug, "deletePoint", id, this.customPoints)
        this.customPoints.remove(id)

        window.localSettings.customPoints = this.customPoints.toJSON()
        window.localSettings.save()
        this.forceUpdate()
    }

    clearPoints = () => {
        debug && console.log(debug, "clearPoints")

        this.customPoints.reset()
        window.localSettings.customPoints = []
        window.localSettings.save()
        this.forceUpdate()
    }

    onClickMap = (ev) => {
        debug && console.log(debug, "onClickMap", ev)

        // If the event is a POI
        if (ev.placeId) {

            // Call event.stop() on the event to prevent the default info window from showing.
            ev.stop();
            debug && console.log(debug, "onClickMap", ev.placeId, ev.latLng)
        }

        window.bus.triggerEvent("info-panel:hide")
    }

    onLoad = (map) => {
        this.map = map

        debug && console.log(debug, "onLoad", this.map)

        this.updateLocation()
    }

    updateLocation = () => {
        debug && console.log(debug, "updateLocation", navigator)

        navigator.geolocation.watchPosition(position=>{
            debug && console.log(debug, "updateLocation position", position)

            const userLocation = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            }
            this.setCenter(userLocation)

            const errorRange = position.coords.accuracy

            if (this.accuracyCircle) {
                this.accuracyCircle.setMap(null)
            }
            // ADDED FROM HERE
            if (this.accuracyCircle) {
                this.accuracyCircle.setCenter(userLocation)
                this.accuracyCircle.setRadius(errorRange)
            } else {
                this.accuracyCircle = new window.google.maps.Circle({
                    center          : userLocation,
                    fillColor       : COLOR.GOOGLE_BLUE_DARK,
                    fillOpacity     : 0.4,
                    radius          : errorRange,
                    strokeColor     : COLOR.GOOGLE_BLUE_LIGHT,
                    strokeOpacity   : 0.4,
                    strokeWeight    : 1,
                    zIndex          : 1,
                })
                this.accuracyCircle.setMap(this.map)
            }


            if (this.marker) this.marker.setPosition(userLocation)
            else {
                this.marker = new window.google.maps.Marker({
                    icon    : {
                        fillColor   : COLOR.GOOGLE_BLUE,
                        fillOpacity : 1,
                        path        : window.google.maps.SymbolPath.CIRCLE,
                        scale       : 8,
                        strokeColor : COLOR.WHITE,
                        strokeWeight: 2,
                    },
                    position: userLocation,
                    title   : 'You are here!'
                })
                this.marker.setMap(this.map)
            }

        }, (e)=>{
            debug && console.log(debug, "updateLocation error", e)
        })
    }

    setCenter(userLocation) {
        debug && console.log(debug, "setCenter", this.isCenteredToUserLocation)

        if (!this.isCenteredToUserLocation) {
            this.isCenteredToUserLocation = true

            const config = Config.get()
            config.center = userLocation
            this.forceUpdate()
        }
    }

    render() {
        debug && console.log(debug, "render", this.state.items, this.customPoints)
        if (!this.state.isLoaded) return <LoadingScreen/>

        const config = Config.get()
        const {points, quests} = this.state.items

        return (
            <React.Fragment>
                <LoadScript googleMapsApiKey={config.googleMapsApiKey}
                            loadingElement={<LoadingScreen/>}>
                    <GoogleMap mapContainerStyle    = {containerStyle}
                               center               = {config.center}
                               zoom                 = {config.zoom}
                               options              = {{
                                   fullscreenControl: false,
                               }}
                               onClick={this.onClickMap}
                               onLoad={this.onLoad}>
                        {_.map(points.models, (data,key)=><MapMarker key={data.id} data={data}/>)}
                        {_.map(quests.models, (data,key)=><MapMarker key={data.id} data={data}/>)}

                        {_.map(this.customPoints.models, (data,key)=><MapMarker key={data.id} data={data}/>)}
                    </GoogleMap>
                </LoadScript>
                <InfoPanel items={this.state.items}/>
                <Add/>
                <Refresh/>
                <Preferences items={this.state.items}/>
            </React.Fragment>
        )
    }
}