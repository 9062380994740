import React from 'react'

import SlidingPanel from "react-sliding-side-panel"
import {IconButton, Switch} from "@mui/material"
import Scrollbars from "react-custom-scrollbars-2"
import Classes from "classnames"
import AddIcon from "../../components/icons/add-icon"
import AddForm from "./add-form"
import AddFromGoogleSheet from "./add-from-google-sheet";

// const debug = false
const debug = "Add"

export default class Add extends React.PureComponent {

    state = {
        isOpen              : false,
        loadFromGoogleSheet : false
    }

    constructor(props) {
        super(props);

        this.panelId = 'add'

        this.updateSize(true)
    }

    componentDidMount() {
        window.bus.subscribe("panel:open", this, this.onPanelOpen)
        window.bus.subscribe("window-resize", this, this.updateSize)
    }

    componentWillUnmount() {
        window.bus.unsubscribe(this)
    }

    updateSize = (noUpdate = false) => {
        const panelSize = window.innerWidth > 700 ? 30 : 100

        if (this.panelSize !== panelSize) {
            this.panelSize = panelSize

            if (!noUpdate) this.forceUpdate()
        }
    }

    onPanelOpen = (id) => {
        if (id !== this.panelId && this.state.isOpen) {
            this.setState({
                isOpen: false
            })
        }
    }

    onToggleAdd = () => {
        this.setState({
            isOpen: !this.state.isOpen
        }, ()=> {
            if (this.state.isOpen) window.bus.triggerEvent("panel:open", this.panelId)
        })
    }

    onLoadFromGoogleSheetToggle = () => {
        this.setState({
            loadFromGoogleSheet: !this.state.loadFromGoogleSheet
        })
    }

    render() {
        debug && console.log(debug, "render", window.localSettings, window.isMobileDevice)

        const classes = Classes("add-panel", {
            'mobile-device' : window.isMobileDevice
        })

        return (
            <React.Fragment>
                <div className="add-button">
                    <IconButton size="large" color="default" onClick={this.onToggleAdd}>
                        <AddIcon />
                    </IconButton>
                </div>
                <SlidingPanel panelClassName    = "add-panel-container"
                              type              = "right"
                              noBackdrop        = {true}
                              isOpen            = {this.state.isOpen}
                              size              = {this.panelSize}>
                    <div className={classes}>
                        <div className="add-panel-title">Новая точка</div>
                        <Scrollbars className="add-panel-scroll">
                            <div className="add-panel-row">
                                <Switch className="add-panel-toggle" checked={this.state.loadFromGoogleSheet} onChange={this.onLoadFromGoogleSheetToggle}/>
                                <div className="add-panel-label">Загрузить из csv</div>
                            </div>

                            {this.state.loadFromGoogleSheet && <AddFromGoogleSheet/>}
                            {!this.state.loadFromGoogleSheet && <AddForm/>}
                        </Scrollbars>
                    </div>
                </SlidingPanel>
            </React.Fragment>
        )
    }
}