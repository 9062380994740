const debug = false
// const debug = "csvToJson"

export default function csvToJson(csv: string) {
    let lines     = csv.split("\r\n")
    const result    = []
    const headers   = _.map(lines[0].split(","), (header:string)=>header.trim())

    debug && console.log(debug, 'process', lines)

    for(let i=1; i<lines.length; i++) {
        const obj = {}

        let row             = lines[i],
            queryIdx        = 0,
            startValueIdx   = 0,
            idx             = 0

        if (row.trim() === '') { continue; }

        while (idx <= row.length) {
            /* if we meet a double quote we skip until the next one */
            let c = row[idx]

            if (c === '"') {
                do { c = row[++idx]; } while (c !== '"' && idx < row.length)
            }

            if (c === ',' || /* handle end of line with no comma */ idx === row.length) {
                /* we've got a value */
                let value = row.substring(startValueIdx, idx).trim()

                debug && console.log(debug, 'process value [A]', value)

                /* skip first double quote */
                if (value[0] === '"') { value = value.substring(1, 1 + value.length - 1); }
                /* skip last comma */
                if (value[value.length - 1] === ',') { value = value.substring(0, value.length - 1) }
                /* skip last double quote */
                if (value[value.length - 1] === '"') { value = value.substring(0, value.length - 1) }

                debug && console.log(debug, 'process value [B]', value)

                let key = headers[queryIdx++]
                // @ts-ignore
                obj[key] = value
                startValueIdx = idx + 1
            }

            ++idx
        }

        result.push(obj)
    }
    return result
}