export const RADIUS_CIRCLE = {
    strokeColor     : '#8b0000',
    strokeOpacity   : 0.6,
    strokeWeight    : 2,
    fillColor       : '#E78587',
    fillOpacity     : 0.15,
    clickable       : false,
    draggable       : false,
    editable        : false,
    visible         : true,
    radius          : 0, // should be modified
    zIndex          : 1
}