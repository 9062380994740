import ModelProto from "../model-proto"
import {ModelType} from "../../constants/model-type"

export default class Quest extends ModelProto {

    type           : ModelType

    category       : string
    class          : string

    leftattempts   : string
    requires_vr    : boolean
    valid          : boolean

    constructor(data: any) {
        super(data)

        this.type           = ModelType.QUEST

        this.category       = data['category']
        this.class          = data['class']

        this.leftattempts   = data['leftattempts']
        this.requires_vr    = data['requires_vr']
        this.valid          = data['valid']
    }

    get infoPrams() {
        let res: any[] = [
            {label: "Номер",                value: this.number},
            {},
        ]

        if (this.description) {
            res.push({label: "Описание",  value: this.description})
            res.push({})
        }

        if (this.valid)
            res.push({label: "Исчезает", value: this.valid})

        res.push({label: "Радиус взятия",   value: '{0} метров'.f(this.radius)})
        res.push({label: "Остаток взятй",   value: this.leftattempts})

        if (this.category) res.push({label: "Категория", value: this.category})

        const available = this.availabilityHours
        if (available) res.push({label: "Доступен", value: available})

        const skills = this.skillValues
        if (skills) res.push({label: "Требует для взятия", value: skills})

        if (this.requires_vr) res.push({label: "Требует VR-оружие", value: this.requires_vr})

        res.push({label: "Класс",       value: this.class})
        res.push({label: "Координаты",  value: this.coordinates})

        return res
    }

    get searchParameters() {
        return _.compact([
            this.name,
        ])
    }

    isSearched(searchedValue: string) {
        return _.some([
            this.number,
        ], (value:number) => value && value.toString().includes(searchedValue)) ||
            _.some([
            this.name,
            this.description
        ], (text:string) => text.toLowerCase().includes(searchedValue))
    }
}
