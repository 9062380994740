// const debug = "LocalSettingsProto"
const debug = false

// This is latest for TypeScript
export default class LocalSettingsProto {

    public localStorageKey  : string
    // public _defaultValues   : any
    // public autoSave         : boolean   = true  // if true will call .save() after every this.set

    // localStorageKey - key we use to save data in local storage
    // default values - default values for our settings
    constructor(localStorageKey:string) {
        debug && console.log(debug, "constructor key=",localStorageKey);

        this.localStorageKey = localStorageKey;
    }

    save() {
        if (debug) console.log(debug, ".save()");
        this._writeToLocalStorage(this.toObject());
    }

    toObject() {
        // should be extended
        console.error("LocalSettingsProto #8472 not extended")
    }

    protected _readFromLocalStorage():any {

        let values : any;

        try {
            values = localStorage.getItem(this.localStorageKey);
            values = JSON.parse(values);
        } catch(err) {
            // @ts-ignore
            // gdEpicFail('local-settings-not-available',err);
            console.error("local-settings-not-available", err)
        }

        if (!values) values = {};

        return values;
    }

    protected _writeToLocalStorage(valuesObject:any) {
        try {
            localStorage.setItem(this.localStorageKey, JSON.stringify(valuesObject));
        } catch (err) {
            // window.gdEpicFail("local-settings-not-available",err);
            console.error("local-settings-not-available", err)
        }

    }

}
