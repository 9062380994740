// @ts-ignore
import {generateUIDWithCollisionChecking} from "../utils/utils"

export interface ModelInterface {
    id      : ID
    toJSON  : {():any}
}

export default class Collection<Model extends ModelInterface> {

    public models   : Model[]           = []
    protected byId  : {[key:ID]:Model}  = {}

    constructor(models: Model[] = []) {

        if (models) {
            this.reset(models)
        }
    }

    // old used in gd.sessionCustomViews ?
    get all() {
        return this.models
    }

    get items() {
        return this.models
    }

    get isEmpty() {
        return !this.models || this.models.length < 1
    }

    get length() {
        return this.models ? this.models.length : 0
    }

    get ids() {
        return _.keys(this.byId)
    }

    reset(models?:Model[]) {
        this.models    = []
        this.byId      = {}

        _.map(models, (model:Model)=>{
            this.add(model)
        })
    }

    add(model:Model) {
        if (!model.id) model.id = generateUIDWithCollisionChecking(this.ids)

        this.models.push(model)
        this.byId[model.id] = model
    }

    addAtPosition(model:Model, postion:number) {
        this.models.splice(postion, 0, model)
        this.byId[model.id] = model
    }

    remove(id:ID) {
        delete this.byId[id]
        return _.remove(this.models, (model:Model)=>model.id === id)
    }

    replace(id: ID, model:Model) {
        if (!model.id) model.id = id

        // find index of model to replace
        const index = _.findIndex(this.models, (model:Model)=>model.id === id)

        // replace model at index
        this.models.splice(index, 1, model)

        delete this.byId[id]        // remove old model
        this.byId[model.id] = model // add new model
    }

    get(id: ID):Model | null {
        return this.byId[id]
    }

    // used when we want to store sort position within collection itself
    // i.e. manual sorting without sortPosition field
    reorderByIds(orderedIds: ID[]):void {
        const result:any[] = []
        _.map(orderedIds, (id:ID)=>{
            result.push(this.get(id))
        })
        this.models = result
    }

    toJSON():any {
        return _.map(this.models, (model:Model)=>model.toJSON())
    }
}




